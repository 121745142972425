<template>
  <div class="polocy">
    <titleNav title="" :left-arrow="true" left-text="" />
    <div v-html="datas.content" class="divz"></div>
    <!-- <div class="lists">
      <div class="images">
        <img :src="datas.cover" alt="" />
      </div>
      <div class="sizes">
          <div class="tit_p">{{datas.title}}</div>
          <div class="com_p">{{datas.time}} e证e卡</div>
      </div>
    </div>
    <div class="zwgd" @click="ckgd()">查看更多</div> -->
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Tabbar,
  TabbarItem,
  NoticeBar,
} from "vant";
import { notice, noticedetail } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      datas: {},
    };
  },
  created() {},
  mounted() {
    this.getlist();
  },

  methods: {
    getlist() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        noticeid: this.$route.query.id,
      };
      noticedetail(data).then((res) => {
        this.datas = res.data.data;
      });
    },
  },
};
</script>

<style lang='scss'>

.polocy {
  width: 100%;
  .lists {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .images {
      width: 35%;
      height: 80px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sizes {
      width: 63%;
      height: 80px;
      position: relative;
      .tit_p {
        width: 100%;
        font-size: 18px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .com_p {
        position: absolute;
        bottom: 0;
        font-size: 12px;
        color: rgb(158, 158, 158);
      }
    }
  }
  .zwgd {
    text-align: center;
    font-size: 14px;
    color: rgb(83, 83, 83);
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
.divz {
  width: 100%;
  p {
    display: inline-block;
    width: 100%;
    img {
      width: 100%;
    }
  }
  img {
      width: 100%;
    }
}
</style>